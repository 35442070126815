import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Berkeley session model API
 */
export default class BerkleySessionModelApi extends APIEndpoint {
  /**
   * Create Berkley session model
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {object} values
   * @returns {Promise}
   */
  createBerkleySessionModel(dossierId, cardId, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/berkeley_model_session`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Berkley session model details
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} berkeleyModelSessionId
   * @returns {Promise}
   */
  getBerkleySessionModelDetails(dossierId, cardId, berkeleyModelSessionId) {
    // eslint-disable-next-line max-len
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/berkeley_model_session/${berkeleyModelSessionId}`,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Update Berkley session model
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} berkeleyModelSessionId
   * @param {object} values
   * @returns {Promise}
   */
  updateBerkleySessionModel(dossierId, cardId, berkeleyModelSessionId, values) {
    // eslint-disable-next-line max-len
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/berkeley_model_session/${berkeleyModelSessionId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete Berkley session model
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} berkeleyModelSessionId
   * @returns {Promise}
   */
  deleteBerkleySessionModel(dossierId, cardId, berkeleyModelSessionId) {
    // eslint-disable-next-line max-len
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/berkeley_model_session/${berkeleyModelSessionId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Get URL Berkley session model
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} berkeleyModelSessionId
   * @returns {Promise}
   */
  getUrlBerkleySessionModel(dossierId, cardId, berkeleyModelSessionId) {
    // eslint-disable-next-line max-len
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/berkeley_model_session/${berkeleyModelSessionId}/get_url`,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }
}
