import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Card API service
 */
export default class CardApi extends APIEndpoint {
  /**
   * Bulk update card information
   */
  updateCardBulk(dossierId: string, values: Record<string, any>) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/bulk`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  getAttachmentFromCard(dossierId: string, cardId: string, attachmentId: string) {
    return fetch(
      `${this.getBaseUrl()}dossiers/${dossierId}/analysis/cards/${cardId}/attachments/${attachmentId}/download`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Token ${this.getToken()}`,
        },
      },
    );
  }

  getCloudAttachmentFromCard(dossierId: string, cardId: string, cloudAttachmentId: string) {
    return fetch(
      `${this.getBaseUrl()}dossiers/${dossierId}/analysis/cards/${cardId}/cloud_attachments/${cloudAttachmentId}/download`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Token ${this.getToken()}`,
        },
      },
    );
  }
}
