import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Contact note API
 */
export default class ContactNoteApi extends APIEndpoint {
  /**
   * Get contact notes
   *
   * @param filters
   * @param sort
   * @param page
   * @param pageSize
   */
  getContactNotes(filters, sort, page = 1, pageSize = 10) {
    const url = this.buildUrl('crm/contact_notes', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Get contact notes from a client
   *
   * @param {string} clientId
   * @param {array} filter
   * @param {string|undefined} sort
   * @param {number} page
   * @param {number} pageSize
   * @returns {Promise}
   */
  getContactNotesFromClient(clientId, filters, sort, page = 1, pageSize = 10) {
    const url = this.buildUrl(
      `crm/clients/${clientId}/contact_notes`,
      filters,
      sort,
      page,
      pageSize,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Contact note details
   *
   * @param contactNoteId
   */
  getContactNoteDetails(contactNoteId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}`,
    );
  }

  /**
   * Create contact note
   *
   * @param {object} values
   */
  createContactNote(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'crm/contact_notes',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Modify contact note
   *
   * @param {string} contactNoteId
   * @param {string} values
   * @returns {Promise}
   */
  updateContactNote(contactNoteId, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Archive contact note
   *
   * @param {string} contactNoteId
   * @returns {Promise}
   */
  archiveContactNote(contactNoteId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}/archive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  /**
   * Unarchive contact note
   *
   * @param {string} contactNoteId
   * @returns {Promise}
   */
  unarchiveContactNote(contactNoteId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}/unarchive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  /**
   * Delete contact note
   *
   * @param {string} contactNoteId
   * @returns {Promise}
   */
  deleteContactNote(contactNoteId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
