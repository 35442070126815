import { FullscreenLayout, Header, Text } from '@elseu/sdu-titan';
import React from 'react';

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <FullscreenLayout
          brandName="Addify"
          duration={300}
          isCloseable={false}
          isModal={false}
          isShown={true}
          zIndex={1012}
        >
          <Header hasContentWrapper={false} title="Helaas ging er iets fout" variant="secondary" />
          <Text color="grey70" spaceAfter={4} type="paragraph">
            Het lijkt erop dat er onverwacht iets fout is gegaan en deze fout is bekend bij ons.
            Probeer de pagina opnieuw te laden of probeer later de pagina opnieuw te openen.
          </Text>
        </FullscreenLayout>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
