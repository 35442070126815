import type { ClientEditFields, ClientUserEditFields } from 'components/client/types';
import JSendRequest from 'services/JSendRequest';
import type { ApiFilter } from 'types/types';

import APIEndpoint from './APIEndpoint';

/**
 * CRM API class
 */
export default class CrmApi extends APIEndpoint {
  // #region clients

  /**
   * Retrieve all clients defined in the crm based on paging and search query given.
   */
  clients(filter: ApiFilter[] = [], sort = '', page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl('crm/clients', filter, sort, page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  clientsWithIntelligenceFilter(
    filter: ApiFilter[] = [],
    intelligenceFilter: string | undefined,
    sort = '',
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const urlGenerated = this.buildUrl('crm/clients', filter, sort, page, maxPerPage);

    // parse the url generated and add intel filter to it and create a new url for it.
    const url = new URL(urlGenerated, this.getBaseUrl());
    const searchUrl = new URLSearchParams(url.search);
    if (intelligenceFilter) {
      searchUrl.append('intelFilter', intelligenceFilter);
    }
    const newUrl = `crm/clients?${searchUrl.toString()}`;

    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(newUrl);
  }

  /**
   * Retrieve all extended company details.
   */
  getClientDetails(clientId: string, withExtraMetaInfo = false): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/clients/${clientId}${withExtraMetaInfo ? '?withMetaInfo=1' : ''}`,
    );
  }

  /**
   * Retrieve all dossiers attached to the given client
   */
  getClientDossiers(
    clientId: string,
    filter: ApiFilter[],
    sort = '',
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const url = this.buildUrl(
      'dossiers',
      [...filter, { name: 'client', value: clientId }],
      sort,
      page,
      maxPerPage,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Retrieve all attachments attached to the given client
   */
  getAttachmentsByClient(
    clientId: string,
    type: string,
    filter: ApiFilter[],
    sort = '',
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const url = this.buildUrl(`crm/clients/${clientId}/${type}`, filter, sort, page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Create a new company.
   */
  createClient(values: ClientEditFields): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'crm/clients',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update the values of a given company.
   */
  updateClient(clientId: string, values: ClientEditFields): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/clients/${clientId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete a crm company.
   */
  deleteClient(clientId: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/clients/${clientId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /// /////

  /**
   * Search for clients
   */
  clientsSearch(search: string): Promise<any> {
    const url = this.buildUrl('crm', search ? [{ name: 'search', value: search }] : []);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Set permission for a client
   */
  clientSetPermissions(id: string, values: { teams: any[] }): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/clients/${id}/set_permissions`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Get permissions for a client
   */
  clientPermissions(id: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/clients/${id}/permissions`,
    );
  }

  /**
   * Get company attachments
   */
  clientAttachments(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`crm/clients/${id}/attachments`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Get company cloud attachments
   */
  clientCloudAttachments(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`crm/clients/${id}/cloud_attachments`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Get company cloud links
   */
  clientCloudLinks(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`crm/clients/${id}/cloud_links`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Get client users linked to a specific client
   */
  clientClientUsers(
    id: string,
    filter: ApiFilter[],
    sort: string,
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const url = this.buildUrl(`crm/clients/${id}/client_users`, filter, sort, page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Retrieve all client users defined in the crm based on paging and filters.
   */
  clientUsers(filter: ApiFilter[], sort = '', page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl('crm/client_users', filter, sort, page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Create a new person.
   */
  createClientUser(values: ClientUserEditFields): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'crm/client_users',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * @todo: REMOVE THIS!
   * Retrieve all extended person details.
   */
  clientUserDetails(id: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(`crm/client_users/${id}`);
  }

  /**
   * Retrieve all extended company contact details.
   */
  getClientUserDetails(clientUserId: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/client_users/${clientUserId}`,
    );
  }

  // getClientUserDossiers

  /**
   * Retrieve all dossiers attached to the given client user
   */
  getClientUserDossiers(
    clientUserId: string,
    filter: ApiFilter[],
    sort = '',
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const url = this.buildUrl(
      'dossiers',
      [...filter, { name: 'clientUser', value: clientUserId }],
      sort,
      page,
      maxPerPage,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Retrieve all attachments attached to the given client user
   */
  getAttachmentsByClientUser(
    clientUserId: string,
    type: string,
    filter: ApiFilter[],
    sort = '',
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const url = this.buildUrl(
      `crm/client_users/${clientUserId}/${type}`,
      filter,
      sort,
      page,
      maxPerPage,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Update the values of a given client user.
   */
  updateClientUser(clientUserId: string, values: ClientUserEditFields): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/client_users/${clientUserId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Get person attachments
   */
  clientUserAttachments(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`crm/client_users/${id}/attachments`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Get person cloud attachments
   */
  clientUserCloudAttachments(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`crm/client_users/${id}/cloud_attachments`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Get person cloud links
   */
  clientUserCloudLinks(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`crm/client_users/${id}/cloud_links`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Get clients linked to the ClientUser
   */
  clientUserClientUser(
    id: string,
    filter: ApiFilter[],
    sort = '',
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const url = this.buildUrl(`crm/client_users/${id}/clients`, filter, sort, page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Update the values of a given person.
   */
  updateClientUserDetails(id: string, values: ClientUserEditFields): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/client_users/${id}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete a crm person.
   */
  deleteClientUser(id: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/client_users/${id}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // endregion

  // #region other
  /**
   * Import crm data.
   */
  crmImport(attachment: File): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).upload(
      'crm/import',
      attachment,
      {},
    );
  }

  // #endregion

  // #region XBRL endpoints

  /**
   * Upload XBRL file to client
   */
  clientXbrlUpload(id: string, file: File): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).upload(
      `crm/clients/${id}/xbrl_upload`,
      file,
    );
  }

  /**
   * Get Visma XBRL indicators for client
   */
  clientXbrlIndicators(id: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/clients/${id}/xbrl_indicators`,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  // #endregion

  // #region filter endpoints

  /**
   * Get the grouped by items of the field sub office of the clients
   */
  clientsFilterSubOfficeNames(): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'crm/clients/filters/sub_office_name',
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  // #endregion

  // region sub offices

  /**
   * Retrieve all sub offices defined the crm based on paging and search query given.
   */
  subOffices(search = '', sort = '', page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(
      'crm/sub_offices',
      search ? [{ name: 'search', value: search }] : [],
      sort,
      page,
      maxPerPage,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Create a new sub office with the given name.
   */
  createSubOffice(name: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'crm/sub_offices',
      JSendRequest.REQUEST_METHOD_POST,
      {
        name,
      },
    );
  }

  // endregion

  // region contact persons
  contactPersons(filters: ApiFilter[] = [], sort = '', page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl('crm/contact_persons', filters, sort, page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  // endregion

  // region move user
  /**
   * Move user
   */
  moveUser(fromUser?: string, toUser?: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'bulk_replace_user',
      JSendRequest.REQUEST_METHOD_POST,
      {
        fromUser,
        toUser,
      },
    );
  }

  // endregion
}
