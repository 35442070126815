import base64 from 'base-64';

import { filterObjectExcept } from '../helpers/generalHelper';
import { convertOwnToolModelPostValues } from '../helpers/owntoolhelper';
import AttachmentFolderApi from './api/AttachmentFolderApi';
import BerkleySessionModelApi from './api/BerkleySessionModelApi';
import BrandingApi from './api/BrandingApi';
import CardApi from './api/CardApi';
import CloudBrowserAPI from './api/CloudBrowserAPI';
import ConsiderationsApi from './api/ConsiderationsApi';
import ContactNoteApi from './api/ContactNoteApi';
import CrmApi from './api/CrmApi';
import DossierApi from './api/DossierApi';
import LabelApi from './api/LabelApi';
import LegalSourceApi from './api/LegalSourceApi';
import NotificationsApi from './api/NotificationsApi';
import OneDriveApi from './api/OneDriveApi';
import OwnSourcesApi from './api/OwnSourcesApi';
import SduCRMHubApi from './api/SduCRMHubApi';
import SearchApi from './api/SearchApi';
import SecurityLoginApi from './api/SecurityLoginApi';
import SharePointApi from './api/SharePointApi';
import SignalingApi from './api/SignalingApi';
import SubOfficeLinkApi from './api/SubOfficeLinkApi';
import TeamApi from './api/TeamApi';
import TemplateAttachmentFolderApi from './api/TemplateAttachmentFolderApi';
import TemplateTaskListApi from './api/TemplateTaskListApi';
import JSendRequest from './JSendRequest';

/**
 * APIService
 *
 */
export default class APIService {
  /**
   * Base url to use when requesting the API for methods.
   * @var {string}
   */
  baseUrl = '';

  /**
   * Token to use on each API request.
   * @var {string|null}
   */
  token;

  /**
   * Class constructor
   * @param {string} baseUrl
   * @param {null|string} token
   */
  constructor(baseUrl, token) {
    this.baseUrl = baseUrl;
    this.token = token;
  }

  // region token

  /**
   * Retrieve the assigned token that is used with all requests to the API.
   * @return {null|string}
   */
  getToken() {
    return this.token;
  }

  /**
   * Clear the current stored token.
   * @return {APIService}
   */
  clearToken() {
    this.token = null;
    return this;
  }

  // #endregion

  // #region baseUrl
  getBaseUrl() {
    return this.baseUrl;
  }

  // #endregion

  /**
   * Retrieve the settings object that represents the
   * @return {Promise}
   */
  settings() {
    return new JSendRequest(this.baseUrl, this.token).request('me');
  }

  // #region authentication
  /**
   * Log in to the api with the given username and password.
   * @param {string} username
   * @param {string} password
   * @returns {Promise}
   */
  login(username, password) {
    const headers = {
      Authorization: `Basic ${base64.encode(`${username}:${password}`)}`,
    };

    return new JSendRequest(this.baseUrl, this.token).request(
      'login',
      JSendRequest.REQUEST_METHOD_GET,
      null,
      headers,
    );
  }

  /**
   * Log out of the API, this will invalidate the current used token.
   * @return {Promise}
   */
  logout() {
    return new JSendRequest(this.baseUrl, this.token).request('logout');
  }

  /**
   * Validates the given login code.
   *
   * @param {string} loginCode
   * @returns {Promise}
   */
  validateLoginCode(loginCode) {
    return new JSendRequest(this.baseUrl, this.token).request(
      'token',
      JSendRequest.REQUEST_METHOD_POST,
      {
        login_code: loginCode,
      },
    );
  }

  // #endregion

  // #region users settings
  /**
   * Uploads a new avatar image for the currently logged-in user.
   *
   * @param {Object} avatarFile
   * @returns {Promise<any>}
   */
  uploadAvatar(avatarFile) {
    return new JSendRequest(this.baseUrl, this.token).upload('me/avatar', avatarFile.file);
  }

  /**
   * Update the notification settings of the currently logged-in user.
   *
   * @param {object} notification
   * @returns {Promise}
   */
  updateNotificationSettings(notification) {
    return new JSendRequest(this.baseUrl, this.token).request(
      'me/settings/notification',
      JSendRequest.REQUEST_METHOD_PATCH,
      notification,
    );
  }

  // #endregion

  /**
   * Get tasks en dossiers for the dashboard.
   *
   * @returns {Promise}
   */
  dashboardTasksAndDossiers() {
    return new JSendRequest(this.baseUrl, this.token).request('dashboard');
  }

  // #region dossiers

  /**
   * Audit log of a dossier
   *
   * @param {string} dossierId
   * @param {number} page
   * @returns {Promise}
   */
  auditDossier(dossierId, page = 1) {
    const url = this.buildUrl(`dossiers/${dossierId}/audit`, [], '', page);
    return new JSendRequest(this.baseUrl, this.token).request(url);
  }

  /**
   * Search dossier by name for autocomplete
   *
   * @param {string|undefined} search
   * @param {number} page
   * @param {number} pageSize
   */
  searchByNameDossier(search, page = 1, pageSize = -1) {
    const url = this.buildUrl(
      'dossiers/search-by-name',
      search ? [{ name: 'search', value: search }] : [],
      '',
      page,
      pageSize,
    );
    return new JSendRequest(this.baseUrl, this.token).request(url);
  }

  /**
   * Add the topic from given dossier
   * @param {string} dossierId
   * @param {string} topicId
   * @returns {Promise}
   */
  createDossierTopic(dossierId, topicId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/topics`,
      JSendRequest.REQUEST_METHOD_POST,
      { id: topicId },
    );
  }

  /**
   * Delete the topic from given dossier
   * @param {string} dossierId
   * @param {string} topicId
   * @returns {Promise}
   */
  deleteDossierTopic(dossierId, topicId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/topics/${topicId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Get the details of a dossier to fill the analysis part (contains all cards for the canvas).
   * @param {string} dossierId
   * @returns {Promise}
   */
  dossierAnalysis(dossierId) {
    return new JSendRequest(this.baseUrl, this.token).request(`dossiers/${dossierId}/analysis`);
  }

  /**
   * Get the advice details of a given dossier.
   * @param {string} dossierId
   * @returns {Promise}
   */
  dossierAdvice(dossierId) {
    return new JSendRequest(this.baseUrl, this.token).request(`dossiers/${dossierId}/advice`);
  }

  /**
   * Get the inventory details of a given dossier.
   * @param {string} dossierId
   * @returns {Promise}
   */
  dossierInventory(dossierId) {
    return new JSendRequest(this.baseUrl, this.token).request(`dossiers/${dossierId}/inventory`);
  }

  /**
   * Get the execution details of a given dossier.
   * @param {string} dossierId
   * @returns {Promise}
   */
  dossierExecution(dossierId) {
    return new JSendRequest(this.baseUrl, this.token).request(`dossiers/${dossierId}/execution`);
  }

  /**
   * Update a card within a dossier.
   * @param {string} dossierId
   * @param {string} cardId
   * @param {object} values
   * @returns {Promise}
   */
  updateCard(dossierId, cardId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Create a new card in a dossier.
   * @param {string} dossierId
   * @param {object} values
   * @returns {Promise}
   */
  createCard(dossierId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Create a new cards in a dossier.
   * @param {string} dossierId
   * @param {object} values
   * @returns {Promise}
   */
  createCardBulk(dossierId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/bulk`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Delete a card in a dossier.
   * @param {string} dossierId
   * @param {string} cardId
   * @returns {Promise}
   */
  deleteCard(dossierId, cardId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Get audit log from a card.
   * @param {string} dossierId
   * @param {string} cardId
   * @param {number|null} page
   * @returns {Promise}
   */
  auditLogCard(dossierId, cardId, page = 1) {
    const url = this.buildUrl(`dossiers/${dossierId}/analysis/cards/${cardId}/audit`, [], '', page);
    return new JSendRequest(this.baseUrl, this.token).request(url);
  }

  /**
   * Details of card in a dossier.
   * @param {string} dossierId
   * @param {string} cardId
   * @returns {Promise}
   */
  detailsOfCard(dossierId, cardId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}`,
    );
  }

  /**
   * Add attachment to a card
   * @param {string} dossierId
   * @param {string} cardId
   * @param {File} file
   * @param {string|undefined} attachmentFolder
   * @param {string|undefined} displayDate
   * @returns {Promise}
   */
  createAttachment(dossierId, cardId, file, attachmentFolder = undefined, displayDate = undefined) {
    return new JSendRequest(this.baseUrl, this.token).upload(
      `dossiers/${dossierId}/analysis/cards/${cardId}/attachments`,
      file,
      { attachmentFolder, displayDate },
    );
  }

  /**
   * Details attachment from card.
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} attachmentId
   * @returns {Promise}
   */
  detailsAttachment(dossierId, cardId, attachmentId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/attachments/${attachmentId}`,
    );
  }

  /**
   * Modify attachment of card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} attachmentId
   * @param {object} values
   * @returns {Promise}
   */
  updateAttachment(dossierId, cardId, attachmentId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/attachments/${attachmentId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete attachment from card.
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} attachmentId
   * @returns {Promise}
   */
  deleteAttachment(dossierId, cardId, attachmentId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/attachments/${attachmentId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Create comment on a card within a dossier.
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} content
   * @param {string} contextType
   * @param {string} contextId
   * @returns {Promise}
   */
  createComment(dossierId, cardId, content, contextType, contextId) {
    let data = {
      content,
    };

    if (contextType && contextId) {
      data = { ...data, context: { id: contextId, type: contextType } };
    }

    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/comments`,
      JSendRequest.REQUEST_METHOD_POST,
      data,
    );
  }

  /**
   * Create a reaction to an existing comment on a card within a dossier.
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} commentId
   * @param {string} content
   * @param {string} contextType
   * @param {string} contextId
   * @returns {Promise}
   */
  createCommentReaction(dossierId, cardId, commentId, content, contextType, contextId) {
    let data = {
      content,
      parentComment: commentId,
    };

    if (contextType && contextId) {
      data = { ...data, context: { id: contextId, type: contextType } };
    }

    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/comments`,
      JSendRequest.REQUEST_METHOD_POST,
      data,
    );
  }

  /**
   * Update a comment.
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} commentId
   * @param {string} content
   * @param {string} contextType
   * @param {string} contextId
   * @returns {Promise}
   */
  updateComment(dossierId, cardId, commentId, content, contextType, contextId) {
    const data = {
      content,
      context: contextType && contextId ? { id: contextId, type: contextType } : null,
    };

    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/comments/${commentId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      data,
    );
  }

  /**
   * Get comments in card, note these require paging.
   * @param {string}dossierId
   * @param {string} cardId
   * @param {number} page
   * @returns {Promise}
   */
  comments(dossierId, cardId, page = 1) {
    const url = this.buildUrl(
      `dossiers/${dossierId}/analysis/cards/${cardId}/comments`,
      '',
      '',
      page,
      10,
    );
    return new JSendRequest(this.baseUrl, this.token).request(url);
  }

  /**
   * Delete comment from card in dossier.
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} commentId
   * @returns {Promise}
   */
  deleteComment(dossierId, cardId, commentId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/comments/${commentId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Create a new own-context item.
   *
   * @param {string} name
   * @param {string} stage
   * @param {string} type
   * @param {string[]} topics
   * @param {File|null|undefined} attachment
   * @param {string} url
   * @return {Promise}
   */
  createOwnContext(name, stage, type, topics, attachment, url) {
    const data = {
      name,
      stage,
      type,
      topics,
    };
    if (attachment) {
      return new JSendRequest(this.baseUrl, this.token).upload('own-tools', attachment, data);
    } else {
      data.url = url;
      return new JSendRequest(this.baseUrl, this.token).request(
        'own-tools',
        JSendRequest.REQUEST_METHOD_POST,
        data,
      );
    }
  }

  /**
   * Create a new own-context item attached to a consideration.
   *
   * @param {string} name
   * @param {string} type
   * @param {string} considerationId
   * @param {File|null|undefined} attachment
   * @param {string} url
   * @return {Promise}
   */
  createOwnContextForConsideration(name, type, considerationId, attachment, url) {
    const data = {
      name,
      type,
      consideration: considerationId,
    };
    if (attachment) {
      return new JSendRequest(this.baseUrl, this.token).upload('own-tools', attachment, data);
    } else {
      data.url = url;
      return new JSendRequest(this.baseUrl, this.token).request(
        'own-tools',
        JSendRequest.REQUEST_METHOD_POST,
        data,
      );
    }
  }

  /**
   * Delete an existing own-context item.
   * @param {string} contextId
   * @return {Promise}
   */
  deleteOwnContext(contextId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `own-tools/${contextId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Get all considerations of the given topics
   * @param {string} section
   * @param {string[]} topicIds
   * @returns {Promise}
   */
  considerationsOfTopics(section, topicIds) {
    const filters = [`id::${topicIds.join(',')}`];
    if (section) {
      filters.push([`section::${section}`]);
    }
    return new JSendRequest(this.baseUrl, this.token).request(
      `topics/considerations?filter=${filters.join('|')}`,
    );
  }

  // #region context actions

  /**
   * Retrieve context overview
   *
   * @param dossierId
   * @param cardId
   * @return {Promise}
   */
  contextOverview(dossierId, cardId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/contexts`,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  /**
   * Add context
   *
   * @param {string} dossierId
   * @param cardId
   * @param contextId
   * @param contextType
   * @param comment
   */
  addContext(dossierId, cardId, contextId, contextType, comment) {
    const body = { id: contextId, type: contextType };

    if (comment && comment.length > 0) {
      body.comment = {
        content: comment,
      };
    }

    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/contexts`,
      JSendRequest.REQUEST_METHOD_POST,
      body,
    );
  }

  /**
   * Delete context
   *
   * @param dossierId
   * @param cardId
   * @param contextId
   */
  deleteContext(dossierId, cardId, contextId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/contexts/${contextId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // #endregion

  // #region related card linking actions
  /**
   * Add a related card to a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} relatedCardId
   * @returns {Promise}
   */
  createRelatedCard(dossierId, cardId, relatedCardId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/link-card/${relatedCardId}`,
      JSendRequest.REQUEST_METHOD_POST,
    );
  }

  /**
   * Remove a related card to a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} relatedCardId
   * @returns {Promise}
   */
  deleteRelatedCard(dossierId, cardId, relatedCardId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/link-card/${relatedCardId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // #endregion

  // #region task list actions
  /**
   * Get all tasks of the dossiers.
   *
   * @param {array} filter
   * @param {string} sort
   * @param {number} page
   * @param {number} maxPerPage
   * @returns {Promise}
   */
  tasksOverview(filter = [], sort = '', page = 1, maxPerPage = 10) {
    const url = this.buildUrl('tasks', filter, sort, page, maxPerPage);
    return new JSendRequest(this.baseUrl, this.token).request(url, JSendRequest.REQUEST_METHOD_GET);
  }

  /**
   * Get tasks of a given dossier.
   *
   * @param {string} dossierId
   * @param {array} filter
   * @param {string} sort
   * @param {number} page
   * @param {number} maxPerPage
   */
  getDossierTaskList(dossierId, filter = [], sort = '', page = 1, maxPerPage = 10) {
    const url = this.buildUrl(`dossiers/${dossierId}/task_lists`, filter, sort, page, maxPerPage);
    return new JSendRequest(this.baseUrl, this.token).request(url, JSendRequest.REQUEST_METHOD_GET);
  }

  /**
   * Create task list for a card
   *
   * @param {string }dossierId
   * @param {string} cardId
   * @param {object} values
   * @returns {Promise}
   */
  createCardTaskList(dossierId, cardId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/task_lists`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update task list for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} taskListId
   * @param {object} values
   * @returns {Promise}
   */
  updateCardTaskList(dossierId, cardId, taskListId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/task_lists/${taskListId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete task list for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} taskListId
   * @returns {Promise}
   */
  deleteCardTaskList(dossierId, cardId, taskListId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/task_lists/${taskListId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // #endregion

  // #region Task list items actions
  /**
   * Create task item for task list
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} taskListId
   * @param {object} values
   * @returns {Promise}
   */
  createCardTaskItem(dossierId, cardId, taskListId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/task_lists/${taskListId}/tasks`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update task item of task list
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} taskListId
   * @param {string} taskId
   * @param {any} values
   * @returns {Promise}
   */
  updateCardTaskItem(dossierId, cardId, taskListId, taskId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/task_lists/${taskListId}/tasks/${taskId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete task item of task list.
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} taskListId
   * @param {string} taskId
   * @returns {Promise}
   */
  deleteCardTaskItem(dossierId, cardId, taskListId, taskId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/task_lists/${taskListId}/tasks/${taskId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // #endregion

  // #region Cloud link actions
  /**
   * Create cloud link for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {object} values
   */
  createCardCloudLink(dossierId, cardId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/cloud_links`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update cloud link for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} cloudLinkId
   */
  getCardCloudLink(dossierId, cardId, cloudLinkId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/cloud_links/${cloudLinkId}`,
    );
  }

  /**
   * Update cloud link for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} cloudLinkId
   * @param {object} values
   */
  updateCardCloudLink(dossierId, cardId, cloudLinkId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/cloud_links/${cloudLinkId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete cloud link for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} cloudLinkId
   */
  deleteCardCloudLink(dossierId, cardId, cloudLinkId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/cloud_links/${cloudLinkId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // #endregion

  // #endregion

  // #region own templates
  // #region own topics

  /**
   * Own topics
   *
   * @param {array} filters
   * @param {string} sort
   * @param {number} page
   * @param {number} limit
   * @return {Promise}
   */
  ownTopics(filters, sort, page = 1, limit = 25) {
    const url = this.buildUrl('own-templates/topics', filters, sort, page, limit);
    return new JSendRequest(this.baseUrl, this.token).request(url);
  }

  /**
   * Create own topic
   *
   * @param {object} values
   * @returns {Promise}
   */
  createTopic(values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      'topics',
      JSendRequest.REQUEST_METHOD_POST,
      filterObjectExcept(values, ['name']),
    );
  }

  /**
   * Update own topic
   *
   * @param {string} topicId
   * @param {object} values
   * @returns {Promise}
   */
  updateTopic(topicId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `topics/${topicId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      filterObjectExcept(values, ['name']),
    );
  }

  /**
   * Delete own topic
   *
   * @param {string} topicId
   * @returns {Promise}
   */
  deleteTopic(topicId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `topics/${topicId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  /**
   * Get topic details
   *
   * @param topicId
   * @returns {Promise}
   */
  topicDetails(topicId) {
    return new JSendRequest(this.baseUrl, this.token).request(`topics/${topicId}`);
  }

  // #endregion
  // #endregion

  // #region own tools
  /**
   * Own tools
   *
   * @param {array} filters
   * @param {string} sort
   * @param {number} page
   * @param {number} limit
   * @return {Promise}
   */
  ownTools(filters, sort, page = 1, limit = 25) {
    const url = this.buildUrl('own-templates/own-tools', filters, sort, page, limit);
    return new JSendRequest(this.baseUrl, this.token).request(url);
  }

  /**
   * Get own tool details
   *
   * @param toolId
   * @returns {Promise}
   */
  toolDetails(toolId) {
    return new JSendRequest(this.baseUrl, this.token).request(`own-tools/${toolId}`);
  }

  /**
   * Create own tool
   *
   * @param {object} values
   * @param {File|null|undefined} attachment
   * @returns {Promise}
   */
  createTool(values, attachment) {
    if (attachment) {
      return new JSendRequest(this.baseUrl, this.token).upload(
        'own-tools',
        attachment,
        convertOwnToolModelPostValues(values),
      );
    } else {
      return new JSendRequest(this.baseUrl, this.token).request(
        'own-tools',
        JSendRequest.REQUEST_METHOD_POST,
        convertOwnToolModelPostValues(values),
      );
    }
  }

  /**
   * Update own tool
   *
   * @param {string} toolId
   * @param {object} values
   * @returns {Promise}
   */
  updateTool(toolId, values) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `own-tools/${toolId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      convertOwnToolModelPostValues(values),
    );
  }

  /**
   * Delete own tool
   *
   * @param {string} toolId
   * @returns {Promise}
   */
  deleteTool(toolId) {
    return new JSendRequest(this.baseUrl, this.token).request(
      `own-tools/${toolId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // #endregion

  /**
   * Get CRM API endpoint
   *
   * TODO: NEED TO FIX TO crm function.
   * @returns {CrmApi}
   */
  getCrm() {
    return new CrmApi(this);
  }

  /**
   * Get Search API endpoint
   *
   * @returns {SearchApi}
   */
  getSearch() {
    return new SearchApi(this);
  }

  /**
   * Get dossier API endpoint
   *
   * TODO: NEED TO FIX TO dossiers function
   * @returns {DossierApi}
   */
  getDossiers() {
    return new DossierApi(this);
  }

  /**
   * Get card API endpoint
   *
   * @returns {CardApi}
   */
  getCards() {
    return new CardApi(this);
  }

  /**
   * Get Signaling API.
   *
   * @returns {SignalingApi}
   */
  getSignaling() {
    return new SignalingApi(this);
  }

  /**
   * Get labels API endpoint
   *
   * @returns {LabelApi}
   */
  getLabels() {
    return new LabelApi(this);
  }

  /**
   * Get the sub office plugin api calls.
   * @return {SubOfficeLinkApi}
   */
  subOfficeLink() {
    return new SubOfficeLinkApi(this);
  }

  /**
   * Get One Drive API endpoint
   *
   * @returns {OneDriveApi}
   */
  oneDrive() {
    return new OneDriveApi(this);
  }

  /**
   * Get cloud browser endpoint
   *
   * @returns {CloudBrowserAPI}
   */
  cloudBrowser() {
    return new CloudBrowserAPI(this);
  }

  /**
   *
   * @returns {ConsiderationsApi}
   */
  considerations() {
    return new ConsiderationsApi(this);
  }

  /**
   *
   * @returns {TeamApi}
   */
  teams() {
    return new TeamApi(this);
  }

  /**
   * @return {AttachmentFolderApi}
   */
  attachmentFolder() {
    return new AttachmentFolderApi(this);
  }

  /**
   *
   * @returns {BerkleySessionModelApi}
   */
  berkleySessionModel() {
    return new BerkleySessionModelApi(this);
  }

  /**
   *
   * @returns {SecurityLoginApi}
   */
  securityLogin() {
    return new SecurityLoginApi(this);
  }

  /**
   *
   * @returns {ContactNoteApi}
   */
  contactNotes() {
    return new ContactNoteApi(this);
  }

  /**
   * @returns {BrandingApi}
   */
  branding() {
    return new BrandingApi(this);
  }

  /**
   * @returns {TemplateTaskListApi}
   */
  templateTaskLists() {
    return new TemplateTaskListApi(this);
  }

  /**
   * @returns {SduCRMHubApi}
   */
  sduCRMHub() {
    return new SduCRMHubApi(this);
  }

  /**
   * @returns {TemplateAttachmentFolderApi}
   */
  templateAttachmentFolders() {
    return new TemplateAttachmentFolderApi(this);
  }

  /**
   * @returns {LegalSourceApi}
   */
  legalSource() {
    return new LegalSourceApi(this);
  }

  /**
   * @returns {OwnSourcesApi}
   */
  getOwnSources() {
    return new OwnSourcesApi(this);
  }

  notifications() {
    return new NotificationsApi(this);
  }

  sharePoint() {
    return new SharePointApi(this);
  }

  // region helper methods to build an url with searching, filtering, sorting and paging.
  /**
   * Helper to build an url with search, filters, sorting, paging and limiting.
   * @param {string} url
   * @param {object[]} filters
   * @param {string} sort
   * @param {number|string} page
   * @param {number|string} maxPerPage
   * @returns {string}
   */
  buildUrl(url, filters = [], sort = '', page = 1, maxPerPage = 25) {
    const query = [`page=${parseInt(page, 10)}`];

    if (maxPerPage) {
      query.push(`maxPerPage=${parseInt(maxPerPage, 10)}`);
    }

    if (sort.length) {
      query.push(`sort=${escape(sort)}`);
    }

    if (filters.length) {
      query.push(`filter=${this.createFilterQueryValue(filters)}`);
    }

    return `${url}?${query.join('&')}`;
  }

  createFilterQueryValue(filters = []) {
    const queryFilters = {};
    filters.forEach((filter) => {
      queryFilters[filter.name] = queryFilters[filter.name] || [];
      queryFilters[filter.name].push(escape(filter.value));
    });

    return Object.keys(queryFilters)
      .map((filterName) => {
        return `${filterName}::${queryFilters[filterName].join(',')}`;
      })
      .join('|');
  }

  // #endregion
}
