import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Legal source API
 */
export default class LegalSourceApi extends APIEndpoint {
  /**
   * Create legal source for a dossier card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {object} values
   * @returns {Promise}
   */
  createLegalSource(dossierId, cardId, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/legal_source`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update legal source
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} legalSourceId
   * @param {object} values
   * @returns {Promise}
   */
  updateLegalSource(dossierId, cardId, legalSourceId, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/legal_sources/${legalSourceId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Delete legal source
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} legalSourceId
   * @returns {Promise}
   */
  deleteLegalSource(dossierId, cardId, legalSourceId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/cards/${cardId}/legal_sources/${legalSourceId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
